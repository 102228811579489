html {
  background-color: #ffffff;
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif;  
}

*,
*:before,
*:after {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}
.main{
  background-color: #f6f6f6;
  overflow-x: hidden;
}

.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1149px;
}
@media only screen and (max-width: 1366px){
  .container {
    padding-left: 25px!important;
    padding-right: 25px!important;
  }
}

@media (min-width: 1300px){
  .container {
    min-width: 1250px;
  }
}

/* Banner */
.banner{
  background: #FFFFFF;
  box-shadow: 0px 4px 105px rgba(34, 48, 74, 0.2);
  border-radius: 0px 0px 10px 10px;
  padding: 10px 20px 10px 10px;
  display: grid;
  gap: 30px;
  align-items: center; 
  grid-template-columns: 0.8fr 2fr 0.7fr;
  position: relative;
  z-index: 3;
}
.banner .logo-box{
  background: #EAF2F8;
  border-radius: 10px;
}
.banner .logo-box a{
  background: url(../image/domainer_logo.svg);
  background-repeat: no-repeat;
  background-position: center center;
  color: transparent;
  display: block;
  font-size: 0;
  padding: 20px 30px;
  height: 138px;
}
.banner .title{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 60px;
  color: #090909;
}
.banner .info{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #7D7F82;
  position: relative;
  margin-left: 15px;
  padding: 0;
}
.banner .info:before{
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 6px;
  height: 100%;
  margin-left: -15px;
  border-radius: 10px;
  background: #F27558;
}
.banner .domaner-btn{
  background: #F27558;
  transition: all 0.5s ease-in-out;
  border-radius: 10px;
  padding: 23px 5px;
  display: block;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  color: #000000;
}
.banner .domaner-btn:hover {
  background-color: rgb(249, 173, 141);
}
@media only screen and (max-width: 1090px) {
  .banner .title{
      font-size: 22px;
      line-height: 32px;
      margin-bottom: 10px;
  }
  .banner .info{
      font-size: 18px;
  }
  .banner .domaner-btn{
      font-size: 18px;
  }
  .banner .domaner-btn {
      font-size: 18px;
      line-height: 22px;
      display: table;
      margin: 0 auto;
      padding: 23px 30px;
  }
}
@media only screen and (max-width: 940px) {
  .banner{
      grid-template-columns: 1fr;
      padding: 10px;
  }
  .banner .logo-box a{
      background: url(../image/domainer_logo_mob.svg);
      background-repeat: no-repeat;
      background-position: center center;
      height: 66px;
  }
  .banner .title{
      line-height: 24px;
      text-align: center;
  }
  .banner .info{
      font-size: 16px;
      line-height: 20px;
      text-align: center;
  }
  .banner .info:before{
      content: none;
  }
}
@media only screen and (max-width: 440px) {
  .banner .domaner-btn {
      display: block;
      padding: 23px 0px;
  }
}

h1 {
  font-style: normal;
  font-weight: 400;
  font-size: 57px;
  line-height: 70px;
  margin-bottom: 38px;
  margin-top: 89px;
  color: #443F3F;
}
h1 span{
  color: #272727;
  text-transform: uppercase;
  font-size: 60px;
  font-weight: 700;
}

@media only screen and (max-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 24px;
  }
}

h1 .thin {
  font-weight: 300;
}

h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 51px;
  margin-bottom: 25px;
  padding-top: 100px;
  text-align: center;
  text-transform: uppercase;
  color: #272727;
}
h3{
  font-size: 30px;
  text-transform: uppercase;
  color: #f86533;
  text-align: left;
  margin-bottom: 30px;
  position: relative;
  padding-left: 35px;
}

@media only screen and (max-width: 768px) {
  h2 {
    font-size: 28px;
    line-height: 36px;
  }
}

p {
  font-size: 18px;
  line-height: 30px;
  color: #272727;
  font-weight: 300;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

.site-logo {
  font-weight: bold;
  font-size: 18px;
  color: transparent;
  position: relative;
}
.site-logo::after{
  background-image: url(../image/logo.svg);
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  content: "";
  left: 0;
  width: 250px;
  height: 250px;
}

.logo-wrapper {
  padding-top: 40px;
  padding-bottom: 22px;
}
.hero {
  color: #fff;
  background-image: url(../image/hero-background.webp);
  background-size: 2002px;
  background-position: center 12px;
  position: relative;
  background-repeat: no-repeat;
}

.header-image{
  margin-left: 60px;
  margin-bottom: -43px;
  position: relative;
  top: -39px;
  z-index: 2;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.header-image::after{
  background-image: url(../image/hero-img-min.webp);
  width: 500px;
  height: 500px;
  background-size: contain;
  background-repeat: no-repeat;
}

.orange{
  display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 58px;
}
.top-green{
  z-index: 2;
  width: 75%;
}
.top{
  z-index: 2;
  margin-top: 550px;
  text-align: center;
  width: 80%;
}
.top p,
.top-green p{
  font-size: 20px;
  line-height: 30px;
  color: white;
  margin-bottom: 55px;
  text-align: center;
}
.top-green p{
  margin-top: 400px;
}
.top-green a{
  font-weight: bold;
  font-size: 18px;
  background-color: white;
  border-radius: 25px;
  padding:15px 46px;
  color: #29888A;
}

.top-green a:hover{
  background-color: #5FBFBE;
  border: 1px solid #4FE7E1;
  color: #fff;
}

.top a{
  color: #F86533;
  font-weight: bold;
  font-size: 18px;
  background-color: white;
  border-radius: 25px;
  padding:15px 60px;
  position: relative;
  z-index: 2;
}


.top a:hover {
  background-color: #FFA57F;
  border: 1px solid #FFCBB5;
  color: #fff;
}

.orange:before{
  position: absolute;
  content: "";
  background-image: url(../image/hero-orange-min.webp);
  width: 266px;
  height: 818px;
  top: 200px;
  background-repeat: no-repeat;
  background-size: contain;
}
.green{
  display: flex;
    position: relative;
    flex-direction: column;
    text-align: center;
    align-items: center;
}
.green:before{
  position: absolute;
  content: "";
  background-image: url(../image/hero-green-min.webp);
  width: 266px;
  height: 818px;
  top: -130px;
  background-size: contain;
  background-repeat: no-repeat;
}
.green:after{
  position: absolute;
  content: "";
  background-image: url(../image/hero-img-min.webp);
  width: 530px;
  height: 640px;
  background-size: contain;
  background-repeat: no-repeat;
  left: -250px;
  top: -20px;
}
.site-header{
  overflow: hidden;
}
.site-header-inner{
  display: grid;
  grid-template-columns: 40% 1fr;
}
/* ENDS HEADER  */

.what-is,
.how{
  text-align: center;
}
.sneaker-one{
  background-image: url(../image/sneaker1-min.webp);
  width: 500px;
  height: 500px;
  background-repeat: no-repeat;

}
.grid-box{
  display: grid;
  grid-template-columns: 50% 1fr;
  margin-top: 35px;
}
.sneaker-text{
  text-align: left;
  padding-top: 25px;
  position: relative;
  z-index: 2;
}
.sneaker-text p:first-of-type{
  padding-bottom: 49px;
}
.how h3:before,
.nft-marketplace h3:before{
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #f86533;
  display: block;
  top: 12px;
  left: 10px;
}
.sneaker-text.pseudo::before{
  width: 0;
  height: 0;
  border-left: 283px solid transparent;
  border-right: 63px solid transparent;
  border-top: 318px solid #EAEAEA;
  position: absolute;
  content: "";
  z-index: -1;
  right: -241px;
  top: 171px;
  transform: rotate(23deg);
  opacity: 0.5;
}

.nft-marketplace{
  text-align: center;
}

.center {
  text-align: center;
}


.sneaker-two{
  width: 500px;
  height: 500px;
  background-repeat: no-repeat;
  background-image: url(../image/sneaker2-min.webp);
  background-position: 80px center;
}
.sneaker-text.second{
  margin-left: 55px;
}
.left-align{
  text-align: left;
  margin-left: 55px;
  position: relative;
  z-index: 2;
}
.left-align p:not(:last-child){
  margin-bottom: 25px;
}

.left-align::before {
  content: "";
  position: absolute;
  background-image: url(../image/polygon-min.webp);
  width: 500px;
  height: 500px;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: -1;
  left: -315px;
}


/* TABS  */
.wrapper {
  margin: auto;
  border-radius: 10px;
  margin-top: 45px;
}

.buttonWrapper {
  display: grid;
  grid-gap: 25px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.contentWrapper {
  margin-top: 25px;
}


button {
  letter-spacing: 3px;
  border: none;
  border-radius: 30px;
  padding: 10px;
  background-color: #ffff;
  color: #bcbcbc;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.5s ease;
}

button:hover {
  background-color: #E1E1E1;
  color: #5F5F5F;
}

button.active {
  background-color: #F86533;
  color: #fff;
}

.active {
  background-color: #F86533;
  color: #fff;
}
.active p{
  color: #fff;
}

p {
  text-align: left;
  padding: 10px;
}

.content {
  display: none;
  border-radius: 30px;
  padding: 20px 20px;
}

.content.active {
  display: grid;
  grid-template-columns: 25% 1fr;
  min-height: 240px;
  grid-gap: 15px;
}

.content.active a {
  font-weight: 700;
  text-decoration: underline;
  color: #fff;
}

a:hover {
  text-decoration: none !important;
}

.rtfk-img{
  background-image: url(../image/rtfkt-min.webp);
  max-width: 100%;
  height: auto;
  background-repeat: no-repeat;
  background-position: center 25px;
}
.gucci-img{
  background-image: url(../image/gucci-min.webp);
  max-width: 100%;
  height: auto;
  background-repeat: no-repeat;
  background-position: center 25px;
}
.asics-img{
  background-image: url(../image/asics-min.webp);
  max-width: 100%;
  height: auto;
  background-repeat: no-repeat;
  background-position: center 25px;
}
.flowers-img{
  background-image: url(../image/flowers-for-society-min.webp);
  max-width: 100%;
  height: auto;
  background-repeat: no-repeat;
  background-position: center 25px;
}
.snkrwars-img{
  background-image: url(../image/snrk-wars\ 2-min.webp);
  max-width: 100%;
  height: auto;
  background-repeat: no-repeat;
  background-position: center 25px;
}
/* ENDS TABS  */

.faq-wrapper {
  position: relative;
  z-index: 2;
}

.faq-wrapper::before {
  content: "";
  position: absolute;
  background-image: url(../image/polygon-small-min.webp);
  background-repeat: no-repeat;
  background-size: contain;
  width: 250px;
  height: 250px;
  z-index: -1;
  transform: rotate(326deg);
  right: -250px;
  top: 115px;
}

.faq-title h3{
  padding-top: 35px;
  margin-bottom: 10px;
  border-top: 1px solid #DBDBDB;
}
.faq-body a {
  text-decoration: underline;
  color: #F86533;
  font-weight: 700;
}

.faq-body {
  padding-bottom: 18px;
}

.faq-body:last-of-type{
  border-bottom: 1px solid #dbdbdb;
}

.about-us p {
  text-align: center;
}

.about-us {
  padding-bottom: 65px;
  position: relative;
  z-index: 2;
}

.about-us::before {
  position: absolute;
  z-index: -1;
  content: "";
  background-image: url(../image/polygon-small-min.webp);
  width: 250px;
  height: 250px;
  background-repeat: no-repeat;
  background-size: contain;
  left: -204px;
  top: 166px;
}

.contact-btn {
  width: 240px;
    height: 60px;
    background-color: #F86533;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    color: #fff;
    align-items: center;
    margin: 30px auto;
    font-size: 22px;
    font-weight: 700;
    
}

.contact-btn:hover {
  background-color: #666666;
}


.contact-btn{
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease;
}

.mobile-ctas {
  display: none;
}

@media only screen and (max-width: 768px) {
  .site-header-inner {
    padding: 26px 20px 28px;
  }
}
.left-align h3{
  margin-top: 25px;
}

@media only screen and (max-width: 768px) {
  .header-image {
    display: none;
  }
}

.header-description {
  margin-bottom: 30px;
}
.header-description.last{
  margin-bottom: 55px;
}
@media only screen and (max-width: 768px) {
  .header-description {
    margin-bottom: 26px;
  }
}

footer .container {
  display: flex;
  justify-content: center;
  align-items: center;
}

footer {
  background-color: #484848;
  padding: 15px;
}

@media only screen and (max-width: 768px) {
  footer .container {
    flex-direction: column;
    padding: 14px;
  }
}

footer .copyright {
  color: #828c98;
  font-size: 14px;
}

.m-visible {
  display: none;
}

.m-hidden {
  display: initial;
}




@media only screen and (min-width: 991px) and (max-width: 1200px) {
  .orange {
    margin-left: 0;
  }
  
  .top-green {
    margin-left: 40px;
  }

  .green:before {
    margin-left: 40px;
  }

  .header-description.last {
    margin-bottom: 35px;
  }
  
  .orange:before {
    top: 231px;
  }
}

/* @media only screen and (max-width: 1200px) {

  .top {
    width: 100%;
  }

  .top a {
    padding: 15px 50px;
  }
} */

/* MOBILE VERSION  */

@media only screen and (max-width: 991px) {
  header,
  article,
  footer {
    padding: 0px 22px;
  }

  footer .container {
    padding: 14px;
  }

  .mobile-detector {
    display: none;
  }

  .m-visible {
    display: initial;
  }

  .m-hidden {
    display: none;
  }

  h1 {
    font-size: 30px;
    text-align: center;
    max-width: 335px;
    margin: 20px auto 38px;
    line-height: 30px;
  }

  h1 span {
    font-size: 39px;
  }

  h2 {
    padding-top: 60px;
  }

  p {
    text-align: center;
    font-size: 16px;
  }

  .left-align p:not(:last-child) {
    margin-bottom: 5px;
  }

  .buttonWrapper {
    display: none;
  }

  .left-align {
    margin-left: 0;
  }

  .content.active {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }

  .content {
    display: grid;
    background-color: tomato;
    border-radius: 0;
    grid-template-rows: 1fr;
    position: relative;
  }

  .content p {
    color: #fff;
  }
  
  .content a {
    font-weight: 700;
    text-decoration: underline;
    color: #fff;
  }

  .content:not(:last-child)::after {
    content: "";
    position: absolute;
    width: 80%;
    height: 1px;
    background-color: #fff;
    bottom: 0;
    left: 10%;
  }

  .content:first-of-type {
    border-radius: 30px 30px 0 0;
  }

  .content:last-of-type {
    border-radius: 0 0 30px 30px;
  }

  .rtfk-img {
    background-position: center 57px;
  }

  .gucci-img {
    height: 163px;
  }

  .flowers-img {
    height: 208px;
  }

  .snkrwars-img {
    height: 160px;
  }

  .asics-img {
    height: 100px;
  }

  .rtfk-img {
    height: 114px;
  }

  .content:last-of-type {
    padding-bottom: 50px;
  }


  .grid-box {
    grid-template-columns: 1fr;
  }

  h3 {
    text-align: center;
    padding-left: 0;
  }

  .how h3:before,
  .nft-marketplace h3:before  {
    content: none;
  }

  .sneaker-text.second {
    margin-left: 0;
  }

  .sneaker-one,
  .sneaker-two {
    margin: 0 auto;
    width: 100%;
    background-size: contain;
    background-position: center center;
  }

  .sneaker-two {
    background-position: 0px center;
    background-size: contain;
  }

  .mobile-ctas {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
  }

  .mobile-ctas a {
    color: #ffff;
    padding: 20px 55px;
    border-radius: 30px;
    font-size: 22px;
  }

  .mobile-ctas a:first-of-type {
    background-color: #F86533;
    margin-bottom: 20px;
  }

  .mobile-ctas a:last-of-type {
    background-color: #29888A;
  }

  .header-description {
    margin-bottom: 0;
  }

  .header-description.last {
    margin-bottom: 26px;
  }

  .logo-wrapper {
    display: flex;
    justify-content: center;
    z-index: 10;
    position: relative;
  }

  .site-logo {
    width: 260px;
  }

  .header-image {
    display: none;
  }

  .site-header-inner {
    grid-template-columns: 1fr;
    position: relative;
    z-index: 10;
  }

  .header-description {
    text-align: center;
  }

  .m-slide-down {
    height: 0;
    overflow: hidden;
    -moz-transition: height 0.5s;
    -ms-transition: height 0.5s;
    -o-transition: height 0.5s;
    -webkit-transition: height 0.5s;
    transition: height 0.5s;
  }
  .m-hidden,
  .mobile-detector {
    display: none;
  }
  .m-visible {
    display: initial;
  }
  .m-hidden {
    display: initial;
  }
  .m-slide-down-measure p {
    margin: 0 !important;
    padding-top: 20px;
  }
  .m-readmore-btn {
    background: transparent;
    border: none;
    text-transform: uppercase;
    font-size: 0;
    font-weight: 500;
    color: white;
    width: 100%;
    margin: 15px auto 50px;
  }
  
  .m-readmore-btn::before {
    cursor: pointer;
    display: inline-block;
    width: 25px;
    height: 25px;
    vertical-align: top;
    margin-left: 5px;
    color: #272727;
    font-size: 24px !important;
  }
  
  .m-readmore-btn.read-more-2::before {
    content: "\002B";
  }
  .m-readmore-btn.collapse-2::before {
    content: "\2212";
  }
  
  .m-readmore-btn * {
    pointer-events: none;
  }
  .m-visible {
    display: initial;
  }
  
  .m-hidden {
      display: none;
  }
  .m-readmore-btn.read-more-3::before{
    content: "See more";
    cursor: pointer;
    text-transform: none !important;
    display: flex;
    justify-content: center;
    color: white;
    font-size: 24px !important;
    width: 100%;
    border-top: 1px solid #344254;
      border-bottom: 1px solid #344254;
      padding-top: 25px;
      padding-bottom: 25px;
      align-items: center;
      font-weight: 300;
      font-size: 16px;
  }

  footer .logo-wrapper {
    padding-bottom: 8px;
  }
}

@mixin transition($params) {
  -webkit-transition: $params;
     -moz-transition: $params;
   -khtml-transition: $params;
       -o-transition: $params;
          transition: $params;
}

@mixin rotate($params) {
  -webkit-transform: rotate($params);
     -moz-transform: rotate($params);
   -khtml-transform: rotate($params);
       -o-transform: rotate($params);
          transform: rotate($params);
}

